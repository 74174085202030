<template>
  <div class="table-container">
    <b-table-simple class="p-table" striped>
      <tbody>

      <tr>
        <td v-if="['PROCEDURE'].includes(useType)" class="td-header"></td>
        <td class="td-header">Código de barras</td>
        <td class="td-header">Nome</td>
        <td class="td-header">Lote</td>
        <td class="td-header">Qtde.</td>
        <td v-if="!hiddenPrice" class="td-header">Custo unitário</td>
        <td class="td-header">Venda unitário</td>
        <td class="td-header">Valor ICMS</td>
        <td class="td-header"></td>
      </tr>

      <tr v-for="(transaction, index) in transactions" :key="transaction.id">
        <td v-if="['PROCEDURE'].includes(useType)" class="td-body">
          <Check v-model="transaction.checked"
                 :disabled="!transaction.sale_price || useType === 'SALE'"
          />
          <div v-if="!transaction.sale_price"
               style="position: absolute; top: 20px; width: 24px; height: 24px; z-index: 1;"
               :id="`check_product_${transaction.batch}_${transaction.product.id}`" />
          <b-tooltip :target="`check_product_${transaction.batch}_${transaction.product.id}`" triggers="hover"
                     placement="bottom">
            <p>Este produto não pode ser vendido, pois não possui valor de venda</p>
          </b-tooltip>
        </td>
        <td class="td-body td-code">{{ transaction.barcode ?? '-' }}</td>
        <td class="td-body td-name">{{ transaction.product?.name }}</td>
        <td class="td-body">
          <span :id="`${transaction.product.id}_${transaction.batch}`" class="batch">{{ transaction.batch || '-'
            }}</span>
          <BatchDetail
            :target="`${transaction.product.id}_${transaction.batch}`"
            :transaction="transaction"
          />
        </td>
        <td class="td-body">
          <div class="quantity">
            <div class="qnt" v-bind:class="{ danger: isBelowMinimum(transaction) }">
              {{ transaction.quantity ? normalizeProductNumber(transaction.quantity, 2) : 0 }}
            </div>

            <span :id="`transaction_${transaction.product.id}`" v-if="isBelowMinimum(transaction)" class="icon-box"><InfoDanger
              class="icon stroke" /></span>

            <b-tooltip :target="`transaction_${transaction.product.id}`" placement="bottom">
              {{ `Necessário ${transaction.target_quantity} e apenas ${transaction.quantity} em estoque` }}
            </b-tooltip>
          </div>
        </td>
        <td v-if="!hiddenPrice" class="td-body cost">{{ parseNumberToMoney(transaction.purchase_price) }}</td>
        <td class="td-body sale_value">{{ transaction.sale_price ? parseNumberToMoney(transaction.sale_price) : '-' }}
        </td>
        <td class="td-body icms_value">{{ transaction.icms_value ? parseNumberToMoney(transaction.icms_value) : '-' }}
        </td>
        <td class="td-body">
          <div class="actions" v-if="editable">
            <span :id="`edit_tooltip_${index}`">
              <b-button
                class="p-0"
                variant="link"
                v-can="'EstEnt3'"
                @click="onEdit(transaction)"
              >
                <Edit
                  class="edit-icon"
                />
              </b-button>
            </span>
            <b-tooltip :target="`edit_tooltip_${index}`" triggers="hover">
              Editar
            </b-tooltip>
            <span>
              <b-button
                class="p-0"
                variant="link"
                v-can="'EstEnt4'"
                :id="`delete_tooltip_${index}`"
                @click="onDelete(transaction)">
                <Delete class="delete-icon" />
              </b-button>
            </span>
            <b-tooltip :target="`delete_tooltip_${index}`" triggers="hover">
              Excluir
            </b-tooltip>
          </div>
        </td>
      </tr>

      </tbody>
    </b-table-simple>

    <div v-if="editable" class="fake-button" @click="$emit('onAddProduct')">
      Adicionar produto
    </div>
    <div class="divider"></div>
    <!-- <div v-if="useType !== 'DISCARD'" class="total-content">
      <div class="title">
        <div class="total-title">Valor total dos produtos</div>
        <div class="total-cost">{{ parseNumberToMoney(totalCost) }}</div>
      </div>
      <div class="title">
        <div class="total-subtitle">Imposto estimado dos produtos (ICMS, IPI, CONFIS entre outros)</div>
        <div class="total-subtitle">{{ parseNumberToMoney(totalTaxes) }}</div>
      </div>
    </div>
    <div v-if="useType !== 'DISCARD'" class="total-content">
      <div class="title">
        <div class="total-title">Valor total de despesas da nota fiscal</div>
        <div class="total-cost">{{ parseNumberToMoney(otherCosts) }}</div>
      </div>
      <div class="title">
        <div class="total-subtitle">Valor do frete + Valor do seguro + Outras despesas acessórias - Desconto</div>
      </div>
    </div> -->
    <div v-if="useType !== 'DISCARD'" class="total-content">
      <div class="title">
        <div class="total-title">Valor total de custo</div>
        <div class="total-value">{{ parseNumberToMoney(totalCost + otherCosts) }}</div>
      </div>
    </div>
    <div v-if="['PROCEDURE', 'SALE'].includes(useType)" class="total-content">
      <div class="title">
        <div class="total-title">Valor total de venda</div>
        <div class="total-value">{{ parseNumberToMoney(totalSales) }}</div>
      </div>
    </div>

    <DeleteModal
      type="produto"
      :description="toDelete && toDelete.product ? toDelete.product.name : ''"
      :confirmedDelete="confirmedDelete"
    />
  </div>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { normalizeProductNumber } from '@/utils/product-helper'

export default {
  components: {
    InfoDanger: () => import('@/assets/icons/info-circle-danger.svg'),
    DeleteModal: () => import('@/components/General/DeleteModal'),
    BatchDetail: () => import('@/components/Warehouse/BatchDetail'),
    Check: () => import('@/assets/icons/check.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Delete: () => import('@/assets/icons/delete.svg')
  },
  props: {
    transactions: Array,
    editable: Boolean,
    useType: String,
    hiddenPrice: Boolean,
    otherCosts: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      toDelete: null
    }
  },
  computed: {
    totalSales: function() {
      let total = 0
      this.transactions.forEach(transaction => {
        total += (transaction.quantity * transaction.sale_price)
      })
      return total
    },
    totalCost: function() {
      let total = 0
      total = this.transactions.reduce((acc, p) => acc + (p.quantity * p.purchase_price), 0)
      return total
    },
    totalTaxes: function() {
      let total = 0
      total = this.transactions.reduce((acc, p) => acc + (p.quantity * p.icms_value), 0)
      return total
    }
  },
  methods: {
    parseNumberToMoney,
    normalizeProductNumber,
    getKitTotalSale(item) {
      const sum = item.kit_products.reduce((sum, kitProduct) => {
        if (kitProduct.selling_cost)
          return sum + parseFloat(kitProduct.selling_cost) * (kitProduct.quantity ?? 1)
        return sum
      }, 0)

      return sum * (item.quantity ?? 1)
    },
    onEdit(transaction) {
      this.$emit('onEdit', transaction)
    },
    onDelete(transaction) {
      this.toDelete = transaction
      this.$bvModal.show('delete-modal')
    },
    confirmedDelete(confirm) {
      if (confirm) {
        this.$emit('onDelete', this.toDelete)
      }
      this.toDelete = null
      this.$bvModal.hide('delete-modal')
    },
    isBelowMinimum(transaction) {
      return transaction.target_quantity && transaction.target_quantity > transaction.quantity
    }

  }
}
</script>

<style lang="scss" scoped>
.table-container {
  margin: 20px 0;

  .table {
    margin: 0 !important;
  }
}

.p-table {
  width: 100%;
  text-align: left;

  td {
    vertical-align: middle !important;
  }

  .td-header {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: var(--type-active);
    border: none;
    font-family: 'Nunito Sans';
  }

  .td-body {
    position: relative;
    padding: 20px 10px;
    border-top: none;
    border-bottom: 1px dashed var(--neutral-300);
    font-weight: 400;
    font-size: 16px;
    color: var(--type-active);
  }

  .td-name {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--neutral-600);
  }

  .more {
    text-align: right;

    svg {
      stroke: var(--neutral-500);
    }
  }

  .quantity {
    display: inline-flex;
    align-items: center;

    svg {
      width: 18px;
      height: 18px;
      margin-top: -3px;
      margin-left: 2px;
    }

    .danger {
      color: var(--states-error);
    }
  }

  .qnt {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--type-active);
  }

  .batch {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--type-active);
  }

  .cost {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--type-active);
  }

  .sale_value {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--type-active);
  }

  .icms_value {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--type-active);
  }

  .edit-text {
    font-size: 16px;
    font-weight: 600;
    color: var(--blue-500);
  }

  .delete-text {
    font-size: 16px;
    font-weight: 600;
    color: var(--states-error);
  }
}

.fake-button {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0 auto;
  text-align: center;
  color: var(--blue-500);
  padding: 20px;
  display: table;
  cursor: pointer;
}

.divider {
  border-bottom: 1px solid var(--neutral-300);
  margin-top: 8px;
  margin-bottom: 8px;
}

.total-content {
  border-bottom: 1px solid var(--neutral-300);
  padding: 8px 16px 8px 16px;

  .title {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  .total-title {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--dark-blue);
  }

  .total-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15.6px;
    color: var(--type-placeholder);
  }

  .total-value {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: var(--blue-700);
  }

  .total-cost {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--blue-700);
  }
}

.actions {
  display: flex;
  align-items: center;
}
</style>
